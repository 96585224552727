<template>
  <facial-instruction
    id="facial-instruction"
    :strategy="strategy"
    :personalization="
      personalInfoConfig.personalData.personalization.instructions
    "
    @click:continue="
      strategy === 'facialRecognition'
        ? $router.push({ name: 'face-recognition' })
        : $router.push({ name: 'take-picture' })
    "
  />
</template>

<script>
import { useNamespacedGetters } from "vuex-composition-helpers/dist"

import FacialRecognitionInstruction from "./components/FacialRecognitionInstructions.vue"

export default {
  components: { facialInstruction: FacialRecognitionInstruction },
  props: {
    strategy: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { personalInfoConfig } = useNamespacedGetters("company", [
      "personalInfoConfig",
    ])
    return {
      personalInfoConfig,
    }
  },
}
</script>
