import { render, staticRenderFns } from "./FacialRecognitionInstructions.vue?vue&type=template&id=22223930&scoped=true&"
import script from "./FacialRecognitionInstructions.vue?vue&type=script&lang=js&"
export * from "./FacialRecognitionInstructions.vue?vue&type=script&lang=js&"
import style0 from "./FacialRecognitionInstructions.vue?vue&type=style&index=0&id=22223930&prod&lang=scss&scoped=true&"
import style1 from "./FacialRecognitionInstructions.vue?vue&type=style&index=1&id=22223930&prod&module=classes&lang=scss&"




function injectStyles (context) {
  
  this["classes"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "22223930",
  null
  
)

export default component.exports