<template>
  <z-card class="card-container-recognition">
    <template #content>
      <section class="mt-5">
        <div
          v-if="
            personalization.useDefault !== true &&
            strategy !== 'facialRecognition'
          "
          class="mt-5 px-5"
        >
          <div class="text-center my-2">
            <img class="my-2" :src="`${personalization.imageUrl}`" />
          </div>
          <div
            :class="classes.div"
            v-html="personalization.text[currentLang]"
          ></div>
        </div>
        <div v-else class="text-content">
          <div class="text-center mb-2">
            <illustration-facial-recognition></illustration-facial-recognition>
          </div>
          <h4 class="body-text text-center">
            {{ $t("recognition.recognition_instructions_title") }}
          </h4>
          <div
            v-if="$vuetify.breakpoint.smAndDown"
            class="my-6"
            style="width: 280px; border: solid 1px #3333; margin: 0 auto"
          ></div>

          <p class="body2 text-center my-4 px-6">
            {{ $t("recognition.recognition_instructions_description") }}
          </p>
          <ul v-for="list in listItems" :key="list.text">
            <li class="d-flex align-center mb-6">
              <z-icon class="pass-alert-icon mx-2" size="18px" color="green">
                $check_in_a_circle
              </z-icon>
              <p class="mb-0 body2">{{ list.text }}</p>
            </li>
          </ul>
        </div>
      </section>
      <div class="btn-container">
        <z-btn
          class="my-2"
          primary
          width="80%"
          :text="$t('recognition.instructions_Button')"
          @click="$emit('click:continue')"
        />
      </div>
    </template>
  </z-card>
</template>

<script>
import { computed } from "vue"

import i18n from "@/i18n"

export default {
  props: {
    personalization: {
      type: Object,
      default: () => ({}),
    },
    strategy: {
      type: String,
      default: "",
    },
    currentLang: {
      type: String,
      default: "",
    },
  },
  setup() {
    const listItems = computed(() => {
      return [
        { text: i18n.tc("recognition.recognition_instructions_list1") },
        { text: i18n.tc("recognition.recognition_instructions_list2") },
        { text: i18n.tc("recognition.recognition_instructions_list3") },
        { text: i18n.tc("recognition.recognition_instructions_list4") },
      ]
    })

    return {
      listItems,
    }
  },
}
</script>

<style lang="scss" scoped>
.body-text {
  padding: 0 20px;
  margin: 0 10px;
}
.card-container-recognition {
  border-radius: 0 !important;
  top: 0;
  width: 100% !important;
  height: 90%;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  padding-bottom: 20px;
}

ul {
  padding: 0 15px;
}

.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
</style>

<style module="classes" lang="scss">
.div {
  height: 100%;
  hr {
    border: solid 1px #3333 !important;
    margin: 25px 0;
  }
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-size: 0.87rem !important;
    line-height: 20px;
    text-align: center;
    font-weight: 400 !important;
    letter-spacing: 0.15px !important;
  }
  h1 {
    font-weight: 300 !important;
    font-size: 1.2rem !important;
    color: var(--black-900);
    letter-spacing: 0.15px !important;
    line-height: 120%;
    text-align: center;
  }

  li,
  ul,
  p {
    font-family: "Roboto";
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 0.8rem !important;
    padding-left: 10px !important;
  }
}
</style>
